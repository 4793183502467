:root {
  --page-bg: #fff;
  --modal-bg: #fff;
  --modal-overlay-bg: rgba(255, 255, 255, 0.75);
  --text: #000;
  --card-bg: #fafafa;
  --card-overlay-bg: #fafafa;
  --radio-selected-bg: #ddd;
  --radio-selected-text: #000;
  --input-bg: #fff;
  --input-border: #ddd;
  --button-bg: #fafafa;
}

.dark {
  --page-bg: #111;
  --modal-bg: #111;
  --modal-overlay-bg: rgba(0, 0, 0, 0.75);
  --text: #fff;
  --card-bg: #1c2126;
  --card-overlay-bg: #1c2126;
  --radio-selected-bg: #1c2126;
  --radio-selected-text: #fff;
  --input-bg: #111;
  --input-border: #1c2126;
  --button-bg: #1c2126;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 20px;
}

html,
body,
#root {
  height: 100%;
}

.app {
  background: var(--page-bg);
  color: var(--text);
  min-height: 100%;
}

header {
  h2 {
    text-transform: uppercase;
    font-size: 0.75rem;
    margin: 0;
  }
}

.container {
  width: 100%;
  max-width: 1140px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.d-grid {
  display: grid;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  grid-gap: 1rem;
  margin: auto;
}

.p-20 {
  padding: 1rem;
}

.px-20 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pt-20 {
  padding-top: 1rem;
}

.pb-20 {
  padding-bottom: 1rem;
}

.m-20 {
  margin: 1rem;
}

.mt-20 {
  margin-top: 1rem;
}

.mb-20 {
  margin-bottom: 1rem;
}

.m-10 {
  margin: 0.5rem;
}

.mt-10 {
  margin-top: 0.5rem;
}

.mb-10 {
  margin-bottom: 0.5rem;
}

.mr-5 {
  margin-right: 5px;
}

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--modal-overlay-bg);

  header {
    display: flex;
    padding: 1rem 4.5rem 1rem 2rem;
    position: relative;
    align-items: center;

    h4 {
      margin: 0;
      font-size: 0.8rem;
    }
  }

  .modal-content {
    background: var(--card-bg);
    box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background: var(--modal-bg);
  }

  .modal-body {
    padding: 1rem 2rem 2rem;
  }

  button.modal-close {
    background: transparent;
    border: 0;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    svg {
      fill: var(--text);
    }
  }
}

.image-specs-form {
  form {
    display: flex;
    flex-direction: column;
  }

  input {
    margin-bottom: 1rem;
    border-radius: 4px;
    border: 1px solid var(--input-border);
    padding: 0.5rem;
    font-size: 1rem;
    color: var(--text);
    background: var(--input-bg);
  }

  label {
    font-size: 0.6rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
}

.button {
  border-radius: 4px;
  border: 0;
  padding: 0.75rem 1rem;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--text);
  background: var(--button-bg);
}

button {
  &:hover {
    cursor: pointer;
  }
}

.card {
  max-width: 20rem;
  background: var(--card-bg);
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.svg-preview-container {
  position: relative;
  overflow: hidden;

  &:hover {
    .svg-preview-actions {
      margin-bottom: 0;
    }
  }
}

.svg-preview {
  width: 100%;
  height: 8rem;
  overflow: hidden;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img,
  embed,
  object,
  iframe {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    border: 0;
  }
}

.svg-preview-actions {
  position: absolute;
  bottom: 0;
  margin-bottom: -3rem;
  transition: margin-bottom 0.2s ease-in-out;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #efefef;
  background: var(--card-overlay-bg);
  box-shadow: 0px -5px 39px 0px rgba(0, 0, 0, 0.06);

  button {
    padding: 0.5rem 1rem;
  }
}

.dark {
  .svg-preview-actions {
    border-top-color: hsla(210, 15%, 18%, 1);
    box-shadow: 0px -5px 39px 0px rgba(255, 255, 255, 0.06);

    button {
      color: #fff;
    }
  }
}

.convert-png {
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 600;
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 0.25rem;
  }
}

.heading-variant {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.radio-button-group {
  display: flex;
  align-items: center;
}

.radio-group-heading {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.6rem;
  margin-bottom: 0.5rem;
}

.radio-button {
  position: absolute;
  left: -9999em;
  top: -9999em;

  & + label {
    padding: 11px 20px;
    cursor: pointer;
    margin-right: -1px;
    text-transform: uppercase;
    font-size: 0.6rem;
    color: var(--radio-selected-text);
    border: 1px solid var(--radio-selected-bg);
    white-space: nowrap;

    &:first-of-type {
      border-radius: 2rem 0 0 2rem;
    }

    &:last-of-type {
      border-radius: 0 2rem 2rem 0;
    }
  }

  &:checked + label {
    color: var(--radio-selected-text);
    background: var(--radio-selected-bg);
    font-weight: 700;
  }
}

.controls {
  margin: 0 -1rem;

  .radio-group {
    padding: 1rem;
  }
}

.button-spinner {
  position: relative;
  min-height: 45px;
}

.lds-ripple {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  transform: scale(0.5);
  top: 2.5px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0.5;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid var(--text);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
